<template>

  <div>
    <!-- Loader Start -->
    <div v-if="this.$store.state.loading_home" class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div class="loading_card_spinner">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
    </div>
    <news_ticker v-if="$store.state.last_news!=0" ></news_ticker>
    <!-- Loader End -->
    <banners></banners>




    <section >
      <div class="container-fluid relative mt-6 lg:mx-6 mx-3">

        <div v-if="sections.length==0" class="empty text-center center-text">

          <img src="/pet_shop/assets/images/ajax-loader.gif" />
          <br>

        </div>

        <div v-else
          class="section_website section_grid grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">

          <div v-for="(item, index) in this.sections" class="relative overflow-hidden group
                 rounded-md shadow dark:shadow-slate-800">
            <router-link :to="{
              name: 'section',
              params: {
                storeName: $store.storeName,
                id: item.id,
                name: item.name,
                lang:$store.state.routeLang
              },
            }" class="text-center" style="background-color: lightgrey;">
              <img :src="item.thumb" class="group-hover:scale-110  section_list_image" :alt="item.name"
                style="background-color: lightgrey;">
              <span class="section_list_name bg-white dark:bg-slate-900
                         group-hover:text-orange-500 py-2
                          px-6 rounded-full shadow dark:shadow-gray-800
                           absolute bottom-4 mx-4 text-lg font-medium">
                {{ item.name }}</span>
            </router-link>
          </div><!--end content-->
        </div><!--end grid-->
        <carousel
        class="sections_mobile"
            style="direction: ltr;"
                :per-page="2"
                :mouse-drag="true"
                loop="true"	
                autoplay="true"
                :autoplayTimeout=5000
                autoplayDirection="backward"
                :paginationEnabled="false"
              >
                <slide  v-for="(item, index) in this.sections" > 
                <!-- Start Hero -->
                <div class="relative overflow-hidden group
                 rounded-md shadow dark:shadow-slate-800 section_item">
            <router-link :to="{
              name: 'section',
              params: {
                storeName: $store.storeName,
                id: item.id,
                name: item.name,
                lang:$store.state.routeLang
              },
            }" class="text-center" style="background-color: lightgrey;">
              <img :src="item.thumb" class="group-hover:scale-110  section_list_image" :alt="item.name"
                style="background-color: lightgrey;">
              <span class="section_list_name bg-white dark:bg-slate-900
                         group-hover:text-orange-500 py-2
                          px-6 rounded-full shadow dark:shadow-gray-800
                           absolute bottom-4 mx-4 text-lg font-medium">
                {{ item.name }}</span>
            </router-link>
          </div>
                  <!-- end Hero -->
                
              </slide>
              </carousel>
        
      </div><!--end container-->
    </section>
   

    <!-- Start -->
    <section 
    class="relative md:py-24 py-16" style="padding-bottom: 0rem !important">
      <div v-if="products.length==0" class="empty text-center center-text">

        <img src="/pet_shop/assets/images/ajax-loader.gif" />
        <br>

      </div>
      <div v-else class="container relative">
        <div class="grid items-end md:grid-cols-2 mb-6">
          <div class="md:text-start text-center">
            <h5 class="font-semibold text-3xl leading-normal mb-4"> {{ $t('Latest products') }}</h5>
            <p class="text-slate-400 max-w-xl">{{ $t('Shop the latest products from the most popular items') }}</p>
          </div>

          <div class="md:text-end hidden md:block">
            <router-link :to="{
              name: 'products',
              params: {
                storeName: $store.storeName, lang:$store.state.routeLang
              },
            }" class="text-slate-400 hover:text-orange-500">
              {{ $t('See More Items') }} <i class="mdi mdi-arrow-right"></i></router-link>
          </div>
        </div><!--end grid-->

        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 pt-6 gap-6">
          <product v-for="(item, index) in this.products" :key="index" :name="item.name" :description="item.description"
            :is_favorit="item.is_favorit" :image="item.thump" :old_price="item.old_price" :new_price="item.new_price"
            :productid="item.id" :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option"
            :is_offer="item.is_offer" :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured"
            :discount="item.discount" :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt" :note="item.note" :range_price="item.range_price">

          </product>
          <!--end content-->

        </div><!--end grid-->
        <div class="md:text-end show_all md:block">
            <router-link :to="{
              name: 'products',
              params: {
                storeName: $store.storeName, lang:$store.state.routeLang
              },
            }" class="text-slate-400 hover:text-orange-500">
              {{ $t('See More Items') }} <i class="mdi mdi-arrow-right"></i></router-link>
          </div>
      </div><!--end container-->

    </section><!--end section-->
    <products_saller></products_saller>
    <products_feautred></products_feautred>
    <sections_home></sections_home>
    <dowenloadApp></dowenloadApp>
  </div>
</template>
<script>
import product from "../components/one_product_list.vue";
import banners from "../components/banners.vue";
import products_saller from "../components/list_products_saller.vue";
import products_feautred from "../components/list_products_feautred.vue";
import sections_home from "../components/sections_home.vue";
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";
import news_ticker from "../components/news_ticker.vue";
import dowenloadApp from "../components/dowenloadApp.vue";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel, 
    Slide,
    news_ticker,
    product,
    banners,
    products_saller,
    products_feautred,
    sections_home,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner,
    dowenloadApp

  },
  data() {
    return {
      settings:
        {
  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ],
  // "centerMode": true,
  "centerPadding": "20px",
  "centerMargin": "20px",
  //  "focusOnSelect": false,
},
      DBitems: null,
      loading: false,
      langChange: "",
      sort: 'id',
      order: 'desc',
      paginate: 12,
      section_length: 0,
      DBitems: null,
      loading: false,
      loadingSection: false,
      loadingProduct: false,
      sections: [
      ],
      products: [
      ],
      page: 1,
      count: 0,
      pageSize: 6,
      langChange: "",
      sort: 'id',
      order: 'desc',
      paginate: 6,
      type: 'new',
     setting: {
  
  "infinite": false,
  "speed": 500,
  "slidesToShow": 2,
  "slidesToScroll": 2,
  "touchThreshold": 6
}
    };
  },
  created() {
    this.loading = true;
    this.loadingSection = true;
    this.loadingProduct = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections/theme8/v2",

        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }

      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this.loadingSection = false

      })
      .then(() => (this.loadingSection = false));


      // new products
      this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/type/new",
      
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
        }
      }
      
    )
    .then((response) => {
      this.products = response.data.data;
     
    })
    .then(() => (this.loadingProduct = false));
      //

    this.$store.state.topbar = 'topbar_light';
    this.$store.state.navbar = 'navbar_light';
  },
  watch: {
    langChange() {
    this.loading = true;
    this.loadingSection = true;
    this.loadingProduct = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections/theme8/v2",

        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }

      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this.loadingSection = false

      })
      .then(() => (this.loadingSection = false));


      // new products
      this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/type/new",
      
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
        }
      }
      
    )
    .then((response) => {
      this.products = response.data.data;
     
    })
    .then(() => (this.loadingProduct = false));
      //

    this.$store.state.topbar = 'topbar_light';
    this.$store.state.navbar = 'navbar_light';
  },
  },
  updated() {
  this.langChange=this.$i18n.locale
},
};
</script>
