<template>
  <div style="min-height:600px">

    <!-- Start Hero -->
    <section class="page_title relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container relative">
        <div class="grid grid-cols-1 mt-14">
          <h3 class="text-3xl leading-normal font-semibold">{{ $t('Checkout') }}</h3>
        </div><!--end grid-->

        <div class="relative mt-3">
          <ul class="tracking-[0.5px] mb-0 inline-block">
            <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
              <router-link to="/home">{{ $t('home') }}</router-link>
            </li>
            <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5
                         ltr:rotate-0 rtl:rotate-180">
              <i class="mdi mdi-chevron-right"></i>
            </li>
            <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">
              {{ $t('Checkout') }}</li>
          </ul>
        </div>
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Hero -->



    <div v-if="loading">
      <hollow-dots-spinner class="loading_card_spinner" :animation-duration="1000" :dot-size="35" :dots-num="3"
        color="var(--primary-color)" />

    </div>

    <!-- Start -->
    <section v-if="items.length != 0" class="relative md:py-24 py-16">
      <div class="container relative">
        <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6 checkout_page">


          <div class="lg:col-span-4">
            <div class="p-6 rounded-md shadow dark:shadow-gray-800">
              <div class="flex justify-between items-center">
                <h5 class="text-lg font-semibold">{{ $t('Your Cart') }}</h5>

                <a href="javascript:void(0)" class="bg-orange-500 flex justify-center
                                 items-center text-white text-[10px] 
                                 font-bold px-2.5 py-0.5 rounded-full h-5">
                  {{ this.items.length }}</a>
              </div>

              <div class="mt-4 rounded-md shadow dark:shadow-gray-800">

                <cart_item v-for="item in this.items" :key="item" :itemId="item">
                </cart_item>

                <div class="p-3 flex justify-between items-center">

                  <h5 class="font-semibold">{{ $t('subTotal') }}</h5>

                  <p class="font-semibold">
                    {{ (PartialPrice * this.$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
                  </p>

                </div>

                <div v-if="this.discountPrice != 0" class="p-3 flex justify-between items-center 
                                border border-gray-100 dark:border-gray-800
                                 bg-gray-50 dark:bg-slate-800 text-green-600">
                  <div>
                    <h5 class="font-semibold">{{ $t('discount') }}</h5>
                    <p class="text-sm text-green-600">({{ discountPrice * this.$store.state.rate }} %)</p>
                  </div>

                  <p class="text-green-600 font-semibold">
                    -{{ ((this.discountPrice * this.PartialPrice * this.$store.state.rate) / 100).toFixed(2) }}

                    {{ this.$store.state.UnitPrice }}</p>
                </div>

                <div v-if="this.selectDeliveryType == 5 && deliveryPrice > 0" class="p-3 flex justify-between
                                  items-center border
                                   border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800
                                  text-red-600">
                  <div>
                    <h5 class="font-semibold">{{ $t('Shipping') }}</h5>
                    <p class="text-sm text-red-600">
                    </p>
                  </div>
                  <div v-if="$store.state.tax > 0">
                    <h5 class="font-semibold">{{ $t('tax') }}
                      ( {{ $store.state.tax }}% )</h5>
                    <p class="text-sm text-green-600">
                      +{{
                        ((
                          (this.$store.state.tax *
                            (this.PartialPrice -
                              (this.discountPrice * this.PartialPrice) / 100 +
                              this.deliveryPrice)) /
                          100
                        ) * this.$store.state.rate).toFixed(2)
                      }} {{ this.$store.state.UnitPrice }}
                    </p>
                  </div>
                  <div>
                    <h5 class="font-semibold"></h5>
                    <p class="text-red-600 font-semibold">

                      +{{ (deliveryPrice * this.$store.state.rate).toFixed(2) }}
                      {{ this.$store.state.UnitPrice }}</p>
                  </div>
                </div>

                <div v-if="$store.state.tax > 0">
                  <h5 class="font-semibold">{{ $t('tax') }}
                    ( {{ $store.state.tax }}% )</h5>
                  <p class="text-sm text-green-600">
                    +{{
                      ((
                        (this.$store.state.tax *
                          (this.PartialPrice -
                            (this.discountPrice * this.PartialPrice) / 100 +
                            this.deliveryPrice)) /
                        100
                      ) * this.$store.state.rate).toFixed(2)
                    }} {{ this.$store.state.UnitPrice }}
                  </p>
                </div>
                <!-- ffffffffffffffffffffffffffffff -->

                <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                  <div>
                    <h5 class="font-semibold">{{ $t('total') }} </h5>
                  </div>

                  <p class="font-semibold">{{ (totalPrice * this.$store.state.rate).toFixed(2) }} {{
                    this.$store.state.UnitPrice }}</p>
                </div>
              </div>

              <div class="subcribe-form mt-6">
                <div class="relative max-w-xl">
                  <input type="text" id="subcribe" name="discount" class="py-4 pe-40 
                                       ps-6 w-full h-[50px] 
                                       outline-none text-black 
                                       dark:text-white rounded-full
                                        bg-white dark:bg-slate-900 
                                        shadow dark:shadow-gray-800" v-model="disCode" :placeholder="$t('Couponcode')">
                  <button class="py-2 px-4 inline-block 
                                     font-semibold tracking-wide 
                                     align-middle duration-500 
                                     text-base text-center absolute 
                                     top-[2px] end-[3px] h-[46px] 
                                     bg-orange-500 text-white rounded-full apply_coupon" @click="Couponcode()">{{
                                      $t('Applay coupon') }}</button>
                  <span style="color:red">{{ couponMsg }}</span>
                </div><!--end form-->
              </div>
            </div>
          </div><!--end col-->

          <div class="lg:col-span-8">
            <div class="p-6 rounded-md shadow dark:shadow-gray-800">
              <h3 class="text-xl leading-normal font-semibold">{{ $t('Billing Details') }}</h3>

              <br>
              <div class="text-center">

                <router-link style="color:white !important;   
                 background-color: var(--primary-color);" type="button" class="py-2 px-5 inline-block 
                              tracking-wide align-middle 
                              duration-500 text-base text-center 
                              text-white rounded-md w-full" to="/login">
                  <span style="color:white !important;" class="text-slate-400 me-2">
                    {{ $t('Already have an account? order guest') }}</span>
                </router-link>
              </div>
              <br>

              <div>
                <label>

                  <input type="checkbox" @change="funshow_guest" />
                  {{ $t('continue as guest') }}
                </label>
              </div>
              <!--  -->
              <div v-if="show_guest">
                <form>
                  <div class="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">

                    <div class="lg:col-span-6">
                      <label class="form-label font-semibold">{{ $t('name') }}: <span
                          class="text-red-600">*</span></label>
                      <input type="text" class="w-full py-2 
                                         px-3 h-10 bg-transparent 
                                         dark:bg-slate-900 dark:text-slate-200 
                                         rounded outline-none border border-gray-100 
                                         dark:border-gray-800 focus:ring-0 mt-2" :placeholder="$t('name')"
                        minlength="3" maxlength="255" name="name" v-model="user_name" id="name">
                    </div>
                    <div class="lg:col-span-6">
                      <label class="form-label font-semibold">{{ $t('Email') }}:
                        <span v-if="this.$store.state.auth == 0" class="text-red-600">*</span></label>
                      <input type="email" class="w-full py-2 px-3 h-10 
                                         bg-transparent dark:bg-slate-900
                                          dark:text-slate-200 rounded
                                           outline-none border border-gray-100
                                            dark:border-gray-800 focus:ring-0 mt-2" placeholder="Email" name="email"
                        id="email" v-model="email">
                      <!--  -->
                      <div v-if="this.$store.state.auth == 0">
                        <label>

                          <input class="account_email_check" type="checkbox" @change="funcreate_account_email"
                            id="account_email" />
                          {{ $t('create account') }}
                        </label>
                      </div>
                      <!--  -->
                    </div>

                    <div class="lg:col-span-6">
                      <label class="form-label font-semibold">{{ $t('Phone') }}: <span
                          class="text-red-600">*</span></label>
                      <input type="text" class=" phone_input w-full py-2 px-3 h-10 
                                         bg-transparent dark:bg-slate-900
                                          dark:text-slate-200 rounded
                                           outline-none border border-gray-100
                                            dark:border-gray-800 focus:ring-0 mt-2" placeholder="phone" name="phone"
                        v-model="phone" dir="ltr" required :minlength=$store.state.country_length
                        :maxlength=$store.state.country_length style="text-align:center" id="phone">
                      <span class="flag">
                        <h2>{{ $store.state.country_code }}</h2>
                        <img :src="this.$store.state.country_flag" />


                      </span>
                      <div v-if="this.$store.state.auth != 0">
                        <label>

                          <input type="checkbox" @change="funcreate_account_email" id="account_email" />
                          {{ $t('create account') }}
                        </label>
                      </div>
                    </div>

                    <div v-if="show_password" class="lg:col-span-6">
                      <label class="form-label font-semibold">{{ $t('password') }}: <span
                          class="text-red-600">*</span></label>
                      <input type="text" class="w-full py-2 px-3 h-10 
                                         bg-transparent dark:bg-slate-900
                                          dark:text-slate-200 rounded
                                           outline-none border border-gray-100
                                            dark:border-gray-800 focus:ring-0 mt-2" v-model="password" dir="ltr"
                        required minlength="8" maxlength="255" id="password">



                    </div>

                  </div>
                  <div>
                    <br>



                  </div>

                  <a v-if="show_password" type="button" class="py-2 px-4
                                     font-semibold 
                                     duration-500 
                                     text-base text-center  
                                     top-[2px] end-[3px] h-[46px] 
                                     bg-orange-500 text-white rounded-full " @click="create_account">{{
                                      $t('register') }}</a>

                  <div v-if="loadingMethods" class="empty text-center center-text">

                    <img src="/pet_shop/assets/images/ajax-loader.gif" />
                    <br>

                  </div>



                </form>
                <!--  -->
                <h3 id="delivery" class="text-xl leading-normal font-semibold mt-6">{{ $t('DeliveryMethod') }}</h3>

                <form action="">
                  <form>
                    <div class="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
                      <div class="lg:col-span-12">


                        <div v-for="(item, index) in this.DBitems.delivery_type.types" :key="index" class="block mt-2">
                          <div>
                            <label :for="'delivery' + item.id" class="inline-flex items-center">
                              <input type="radio" name="delivery_option" :id="'delivery' + item.id" :value="item.id"
                                @click="selectDeliveryTypeId(item.id)" class="
                                                        border-gray-100 dark:border-gray-800
                                                         text-orange-500 
                                                          me-2">
                              <span class="text-slate-400">{{ item.type }}</span>
                              <span v-if="item.id == 5" class="text-slate-400"> ({{ $t('cost by region') }})</span>
                            </label>
                          </div>
                        </div>


                      </div>



                    </div>
                  </form>

                  <div class="lg:col-span-4">

                    <label class="font-semibold">{{ $t('selectAddress') }}:</label>
                    <!--  -->

                    <div class="grid lg:grid-cols-12 grid-cols-1 gap-5">

                      <div class="lg:col-span-12">


                        <div class="mb-4">
                          <label class="font-semibold" for="countries">
                            {{ $t('countries') }} *:</label>

                          <v-select v-model="country" :reduce="(option) => option.id" :options="countries" label="city"
                            :placeholder="$t('country')" class="form-select form-input mt-2 
                w-full px-3 h-10 bg-transparent 
                dark:bg-slate-900 dark:text-slate-200 
                rounded outline-none border border-gray-100 
                dark:border-gray-800 focus:ring-0" id="country">
                          </v-select>

                        </div>
                      </div>

                      <div class="lg:col-span-6">


                        <div class="mb-4">
                          <label class="font-semibold" for="city">
                            {{ $t('city') }} *:</label>

                          <v-select v-model="city" :reduce="(option) => option.id" :options="cities" label="city"
                            :placeholder="$t('city')" class="form-select form-input mt-2 
                                        w-full px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0" id="city">
                          </v-select>

                        </div>
                      </div>

                      <div class="lg:col-span-6">
                        <div class="mb-4">
                          <label class="font-semibold" for="region">
                            {{ $t('region') }} *:</label>

                          <v-select v-model="region" :reduce="(option) => option.id" :options="regions" label="city"
                            :placeholder="$t('region')" class="form-select form-input mt-2 
                                        w-full py-2 px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0" id="region">
                          </v-select>

                        </div>
                      </div>

                    </div>

                    <div class="grid lg:grid-cols-12 grid-cols-1 gap-5">
                      <div class="lg:col-span-6">
                        <div class="mb-4">
                          <label class="font-semibold" for="street">
                            {{ $t('street') }} :</label>
                          <input class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" autocomplete="false" required
                            type="text" :placeholder="$t('street')" maxlength="255" id="street" v-model="street">
                        </div>
                      </div>
                      <div class="lg:col-span-6">
                        <div class="mb-4">
                          <label class="font-semibold" for="building">
                            {{ $t('building') }} :</label>
                          <input class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" name="name" autocomplete="false"
                            type="text" :placeholder="$t('building')" maxlength="255" v-model="building" id="building">
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label class="font-semibold" for="detailes">
                        {{ $t('OtherDetails') }} :</label>
                      <textarea v-model="other_details" class="mt-3 w-full py-2
                                             px-3  bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" id="detailes">
                                    </textarea>
                    </div>
                    <!--  -->


                  </div>
                </form>


                <h3 id="payment" class="text-xl leading-normal font-semibold mt-6">{{ $t('PaymentMethod') }}</h3>

                <form action="">
                  <form>
                    <div class="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
                      <div class="lg:col-span-12">


                        <div v-for="(item, index) in this.DBitems.payment_types.types" :key="index" class="block mt-2">
                          <div>
                            <label class="inline-flex items-center">
                              <input @click="selectPaymentTypeId(item.id)" type="radio" class="border-gray-100 
                                                        dark:border-gray-800 text-orange-500 
                                                        
                                                           me-2" name="radio_payments" :value="item.id">
                              <span class="text-slate-400">{{ item.type }}</span>
                            </label>
                          </div>
                        </div>
                      </div>




                    </div>
                  </form>
                </form>

                <div class="mt-4">
                  <input type="submit" v-if="!loadingMethods && this.DBitems != null" class="py-2 px-5 inline-block 
                                 tracking-wide align-middle 
                                 duration-500 text-base text-center 
                                 bg-orange-500 text-white rounded-md w-full" :value="$t('Continue to checkout')"
                    id="confirm_btn" @click="confirmOrder()">
                </div>
              </div>
            </div>

          </div><!--end col-->


        </div><!--end grid-->
      </div><!--end container-->

    </section><!--end section-->

    <div v-else>
      <div class="container">
        <h4 style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; margin-top:1%;">{{
          $t('cartEmpty') }} !!</h4>
        <a style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; 
      margin-top:1%;
      cursor:pointer;
      " @click="$router.push({
        name: 'home',
        params: { storeName: $store.storeName, lang: $store.state.routeLang },
      })
        ">{{ $t("Continueshopping") }}</a>

        <img v-if="this.items.length == 0" style="width: 50%; margin-left: 25%; margin-right: 25%;    
         filter: grayscale(0.85);" src="https://etrolley.net/store/assets/image/emptyCard.gif" />


      </div>
    </div>

    <!-- End -->

  </div>
</template>


<script>
import cart_item from "../components/item_cart_order.vue";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

import { HollowDotsSpinner } from "epic-spinners";

export default {
  components: {
    Vodal,
    cart_item,
    HollowDotsSpinner
  },
  data: function () {
    return {
      loading: true,
      user_name: null,
      phone: null,
      email: null,
      items: [],
      password: null,
      products: [],
      products_cost: [],
      loadingMethods: false,
      addresses: [],
      addAddress: false,
      showAddress: false,
      showMonthes: false,
      DBitems: null,
      discount_delivery_free: 0,
      selectPaymentType: null,
      selectDeliveryType: null,
      selectAddress: null,
      count_month: null,
      disCode: null,
      discount_id: null,
      PartialPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      couponMsg: "",
      unit: this.$store.state.unit,
      allOrderNote: "",
      langChange: "",
      cities: [],
      regions: [],
      city: null,
      region: null,
      street: null,
      building: null,
      street: null,
      other_details: null,
      show_guest: false,
      show_password: false,
      country: null,
      countries: [],
    };
  },
  created() {
    this.$store.state.topbar = 'topbar_light';
    this.$store.state.navbar = 'navbar_dark';
    if (this.$i18n.locale == 'ar') {
      this.unit = this.$store.state.unitAR;
    }
    else {
      this.unit = this.$store.state.unit;
    }

    //
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost = [];
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        console.log('$$$$$$ ')

        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;

          console.log('$$$$$$ offer price ', i, '  ', this.PartialPrice)
        } else {

          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          console.log('$$$$$$  total_price ', i, ' ', this.PartialPrice)
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }
    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ', this.products_cost);


    this.loadingMethods = true;
    if (this.products_cost.length != 0) {
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          {
            products: this.products_cost,
          },
          {

            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {

          }
        });

    }

    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/guest/addresses/create",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.cities = response.data.data.cities;
        this.countries = response.data.data.countries
      }).then();

  },
  watch: {
    langChange() {
      //  this.loading = false;
      if (this.$i18n.locale == 'ar') {
        this.unit = this.$store.state.unitAR;
      }
      else {
        this.unit = this.$store.state.unit;
      }
      //get profile

      //
      let AllCookies = this.$cookies.keys();
      this.items = [];
      this.products = [];
      this.products_cost = [];
      this.PartialPrice = 0;
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));
          ////PartialPrice
          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
          } else {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          }
          // console.log('products',pluck(this.products,"product_id"));
        }
      }

      for (let i = 0; i < this.products.length; i++) {
        this.products_cost.push(this.products[i]['product_id']);
      }
      console.log('product_cost: ', this.products_cost);


      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);
    }
    ,
    PartialPrice() {
      //$store.state.tax
      //console.log("this.$store.state.tax "+ this.$store.state.tax + "  " +this.$store.state.tax+  this.PartialPrice)
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;

    },
    discountPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;

    },
    deliveryPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;

    },
    selectDeliveryType() {
      if (this.selectDeliveryType == 5) {
        this.showAddress = true;
        //console.log("this.showAddress" + this.showAddress);
      } else {
        this.showAddress = false;
      }
    },
    selectPaymentType() {
      if (this.selectPaymentType == 4) {
        this.showMonthes = true;
      } else {
        this.showMonthes = false;
        this.count_month = null;
      }
      //console.log( this.count_month)
    },

    country(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/cities/" +
          this.country,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
          if (this.cities.find((o) => o.id == this.city) == null) {
            this.city = null;
          }
        });
    },
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/regions/" +
          this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.regions = response.data.data.regions;
          if (this.regions.find((o) => o.id == this.region) == null) {
            this.region = null;
          }
        });
    },
    region(newVal) {
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/cost_delivery",
          {
            region_id: newVal,
            delivery_id: 5,
            cost: this.PartialPrice,
            products: this.products_cost,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            if (this.discount_delivery_free == 0) {
              // console.log(this.discount_delivery_free);
              this.deliveryPrice = response.data.data.cost;
            }
            this.selectDeliveryType = 5// id;
            //console.log(this.selectDeliveryType);
            for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == 5) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
            // this.discountPrice = response.data.data.discount;
          } else {
            alert(response.data.message);
            this.selectAddress = null;

            // const $select = document.querySelector("#addressSelect");
            //$select.value = "SelectOne";
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then(() => {
          this.loadingMethods = false;
        })
        .catch((error) => {
          if (error.response.status == "401") {
            //  this.$localStorage.remove(this.$store.storeName);
            // window.location.href =
            //   "/";
          }
        });
    },

  },
  updated() {
    this.langChange = this.$i18n.locale
  },
  methods: {
    funshow_guest() {
      if (this.show_guest == false)
        this.show_guest = true;
      else
        this.show_guest = false;

    },
    close_address() {
      this.$store.state.addAddress = false;
    },
    open_address() {
      this.$store.state.addAddress = true;
    },
    updatAddress() {
      ///////////addresses
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    updatedata2() {
      console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
      this.langChange_check = false;
      this.langChange = false;
      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);

    },
    Couponcode() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/use_coupon",
          {
            code: this.disCode,
            total_price: this.PartialPrice,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.couponMsg = "";
            this.discountPrice = response.data.data.discount;
            this.discount_id = response.data.data.discount_id;
            this.discount_delivery_free = response.data.data.isdelivery_free;
            if (this.discount_delivery_free == 1)
              this.deliveryPrice = 0;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          } else {
            this.couponMsg = response.data.message;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    selectPaymentTypeId(id) {
      document.getElementById('payment').style = "color:black";
      this.loadingMethods = true;
      this.selectPaymentType = id;
      //console.log(this.selectPaymentType);

      for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
        this.DBitems.payment_types.types[el].isActive = false;
        if (this.DBitems.payment_types.types[el].id == id) {
          this.DBitems.payment_types.types[el].isActive = true;
        }
        //console.log(this.DBitems.payment_types.types[el].isActive);
      }

      //setTimeout(() => {
      this.loadingMethods = false;
      //  }, 3000);
    },
    selectDeliveryTypeId(id) {
      console.log('bbbbbbbbbbbbbbbbb');
      //  this.loadingMethods = true;
      this.selectDeliveryType = id;
      if (id == 5) {
        this.showAddress = true;
        console.log('aaaaaaa');

      }
      document.getElementById('delivery').style = "color:black";
    },

    onChangeMonths: function (event) {
      //console.log( event.target.value)
      this.count_month = event.target.value;
    },
    confirmOrder() {
      this.change_state();
      document.getElementById("confirm_btn").disabled = true;
      var count_error = 0;
      var error_msg = "";

      if (this.selectPaymentType == null) {
        count_error++;
        document.getElementById('payment').style = 'color:red'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة الدفع");
        } else {
          error_msg += ("Please select a payment type ");
        }
      }

      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الاسم");
        } else {
          error_msg += (" Please Enter Name");
        }
      }

      if (this.selectDeliveryType == null) {
        document.getElementById('delivery').style = 'color:red'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة التوصيل");
        } else {
          error_msg += (" Please select a delivery type");
        }
      }

      if (this.selectDeliveryType == 5 && this.region == null) {
        document.getElementById('region').style = 'border:2px solid red !important'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += ("يجب اختيار المنطقة ");
        } else {
          error_msg += (" Please Select A Region");
        }
      }


      if (this.phone == null) {
        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الهاتف");
        } else {
          error_msg += (" Please Enter Phone");
        }
        document.getElementById('phone').style = 'border:2px solid red !important'
      }
      if (count_error == 0) {
        this.loading = true;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/order/store/v2",
            {

              delivery_type_id: this.selectDeliveryType,
              payment_type_id: this.selectPaymentType,
              total_price: this.totalPrice,
              discount_id: this.discount_id,
              products: this.products,
              part_price: this.PartialPrice,
              note: this.allOrderNote,
              count_month: this.count_month,
              device: 'web',
              user_name: this.user_name,
              phone: this.phone,
              email: this.email,
              region_id: this.region,
              street: this.street,
              building: this.building,
              other_details: this.other_details

            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              if (response.data.data.clear_cart == null || response.data.data.link_pay == null) {
                for (let i = 0; i < this.items.length; i++) {
                  this.$cookies.remove(this.items[i]);
                }
                this.items = [];

                console.log("hiiiiiiii" + this.$cookies.keys());
                this.$store.state.cartCounter = 0;
              }

              //console.log("hiiiiiiii" + this.$cookies.keys());

              if (response.data.data.link_pay == null) {
                this.$store.state.cartCounter = 0;
                this.$router.push({
                  name: "order_done",
                  params: {
                    storeName: this.$store.storeName,
                    lang: this.$i18n.locale,
                    id: response.data.data.order_id
                  },
                });
              }

              if (response.data.data.link_pay != null) {
                window.location.href = response.data.data.link_pay;
              }

              //this.deliveryPrice = response.data.data.cost;
              // this.discountPrice = response.data.data.discount;
            } else {
              alert(response.data.message);
              document.getElementById("confirm_btn").disabled = false;
              var count_error = 0;
              var error_msg = "";
              // this.couponMsg = response.data.message;
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              // window.location.href =
              //   "/"+this.$i18n.locale ;
            }
          });
      }
      else {
        alert(error_msg);
        document.getElementById("confirm_btn").disabled = false;

      }


      //////////

      //////////

    },
    change_state() {
      document.getElementById('payment').style = "color:black";
      document.getElementById('delivery').style = "color:black";
      if (document.getElementById('region'))
        document.getElementById('region').style = "border: 0px solid transparent !important;";
      document.getElementById('phone').style = "border: 0px solid transparent !important;";
      document.getElementById('name').style = "border: 0px solid transparent !important;";

    },
    funcreate_account_email() {
      console.log('')

      if (document.getElementById('account_email').checked == true)
        this.show_password = true;
      else
        this.show_password = false;
    },

    create_account() {

      var count_error = 0;
      var error_msg = "";
      document.getElementById('phone').style = "border: 0px solid transparent !important;";
      document.getElementById('name').style = "border: 0px solid transparent !important;";
      document.getElementById('email').style = "border: 0px solid transparent !important;";
      document.getElementById('password').style = "border: 0px solid transparent !important;";

      if (this.phone == null) {
        count_error++;
        document.getElementById('phone').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الرقم");
        } else {
          error_msg += ("Please Add Phone ");
        }
      }


      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الاسم");
        } else {
          error_msg += ("Please Add User Name ");
        }
      }

      if (this.password == null) {
        count_error++;
        document.getElementById('password').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال كلمة السر");
        } else {
          error_msg += ("Please Enter Password ");
        }
      }

      if (this.email == null &&this.$store.state.auth == 0) {
        count_error++;
        document.getElementById('email').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الايميل");
        } else {
          error_msg += ("Please Add Email");
        }
      }
      if (count_error == 0) {
        this.loading = true;
        console.log(this.phone)

        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/register",
            {
              phone: this.$store.state.country_code + this.phone,
              name: this.user_name,
              email: this.email,
              password: this.password

            },

            {

              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.loading = false;

            if (response.data.code == "1") {

              this.$localStorage.set(
                this.$store.storeName,
                response.data.data.token
              );
              this.$localStorage.set(
                this.$store.user_name,
                response.data.data.name
              );
              if (!response.data.data.name || response.data.data.name == '') {
                this.$localStorage.set(
                  this.$store.user_name,
                );
              }
              console.log(
                this.$localStorage.get(this.$store.storeName) +
                " id : " +
                this.$store.storeName
              );

              console.log(
                "user token : " +
                this.$localStorage.get(this.$store.storeName)
              );

              window.location.href = '/guest_store/checkout';
              this.loading = false;
            } else if (response.data.code == "-1") {
              this.loading = false;
              this.errorMessage = response.data.message;
              setTimeout(() => {
                this.errorMessage = "";
                this.count_error = 0;
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessage = "response.data.error";
              setTimeout(() => {
                this.errorMessage = "";
              }, 3000);
            }

          });
      }
      else {
        alert(error_msg);
        setTimeout(() => {

          console.log("hhhhhhhhhhhhhhhhhhhhh");
          var count_error = 0;
          var error_msg = "";
          document.getElementById('phone').style = "border: 0px solid transparent !important;";
          document.getElementById('name').style = "border: 0px solid transparent !important;";
          document.getElementById('email').style = "border: 0px solid transparent !important;";
          document.getElementById('password').style = "border: 0px solid transparent !important;";
        }, 8000);
      }




    }
  },
};

</script>