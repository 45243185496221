import Vue from 'vue'
import VueRouter from 'vue-router'




//theme8
import theme8_home from '../theme8/views/home.vue';
import theme8_sections from '../theme8/views/sections.vue';
import theme8_products from '../theme8/views/products.vue';
import BrandsView8 from '../theme8/views/brands.vue';
import AboutView8 from '../theme8/views/about.vue';
import ContactUsView8 from '../theme8/views/contactUs.vue';
import productView8 from '../theme8/views/product.vue';
import BrandView8 from '../theme8/views/brand_products.vue';
import PrivacyView8 from '../theme8/views/privacy.vue';
import theme8_section from '../theme8/views/section.vue';
import LoginView8 from '../theme8/views/login.vue';
import LoginViewPhone8 from '../theme8/views/Loginphone.vue';
import LoginViewPhoneSms8 from '../theme8/views/Loginphone2.vue';
import FavoritesView8 from '../theme8/views/favourite.vue';
import RegisterView8 from '../theme8/views/register.vue';
import ActivateCodeView8 from '../theme8/views/activeCode.vue';
import ProfileView8 from '../theme8/views/profile.vue'
import ShoppingCartView8 from '../theme8/views/cart.vue';
import ConfirmOrderView8 from '../theme8/views/confirmOrder.vue';
import MyAddressView8 from '../theme8/views/my_address.vue';
import SearchView8 from '../theme8/views/search.vue';
import NotificationsView8 from '../theme8/views/myNotification.vue';
import InvoiceView8 from '../theme8/views/order.vue';
import MyOrdersView8 from '../theme8/views/myOrders.vue';
import OffersView8 from '../theme8/views/products.vue';
import OrderDone8 from '../theme8/views/OrderDone.vue';
import ConfirmOrderGuestView8 from '../theme8/views/confirmOrderGuest.vue';


Vue.use(VueRouter)
const routes = [{
            path: '/:lang(ar|en)?',
            name: 'home1',
            components: {
               
                theme8: theme8_home,
            }
        },
        {
            path: '/:lang(ar|en)?/',
            name: 'home',
            components: {
                
                theme8: theme8_home,
              
            }
        },
        {
            path: '/:lang(ar|en)?/register',
            name: 'register',
            components: {
               
                theme8: RegisterView8,
               
            }
        },
        {
            path: '/:lang(ar|en)?/activateCode',
            name: 'activateCode',
            components: {
               
                theme8: ActivateCodeView8,
               

            },
            props: {
                
                theme8:true,
            },

        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'login',
            components: {
               
                theme8: LoginView8,
                
            }
        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'loginPhone',
            components: {
              
                theme8: LoginViewPhone8,
            }
        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'loginPhonesms',
            components: {
                
                theme8: LoginViewPhoneSms8,
            }
        },
        {
            path: '/:lang(ar|en)?/about',
            name: 'about',
            components: {
                
                theme8: AboutView8,
            }
        },
       
        {
            path: '/:lang(ar|en)?/privacy',
            name: 'privacy',
            components: {
              
                theme8:PrivacyView8,
            }
        },
        {
            path: '/:lang(ar|en)?/contactUs',
            name: 'contactUs',
            components: {
              
                theme8: ContactUsView8,
                
            }
        },
        {
            path: '/:lang(ar|en)?/myorders',
            name: 'myOrders',
            components: {
               
                theme8: MyOrdersView8,

            }
        },
        
        {
            path: '/:lang(ar|en)?/myorders/failed',
            name: 'myOrdersFailed',
            components: {
               
                theme8: ConfirmOrderView8,
            }
        },
        {
            path: '/:lang(ar|en)?/myorders/success',
            name: 'myOrdersSuccess',
            components: {
              
                theme8: MyOrdersView8,
            }
        },
        
        {
            path: '/:lang(ar|en)?/invoice/:id',
            name: 'invoice',
            components: {
               
                theme8: InvoiceView8,
                
        
            }
        },
        {
            path: '/:lang(ar|en)?/myAddresses',
            name: 'myAddresses',
            components: {
               
                theme8: MyAddressView8,
            }
        },
        {
            path: '/:lang(ar|en)?/favorites',
            name: 'favorites',
            components: {
              
                theme8: FavoritesView8,
            }
        },
        {
            path: '/:lang(ar|en)?/profile',
            name: 'profile',
            components: {
               
                theme8: ProfileView8,

            }
        },
        {
            path: '/:lang(ar|en)?/sections',
            name: 'sections',
            components: {
            
                theme8:theme8_sections,
            },
        },
        {
            path: '/:lang(ar|en)?/section/:id/:name',
            name: 'section',
            components: {
             
                theme8 : theme8_section,
            },
            props: {
              
                theme8: true
            },
        },
        
        {
            path: '/:lang(ar|en)?/offers',
            name: 'offers',
            components: {
              
                theme8: OffersView8,
            }
        },
        {
            path: '/:lang(ar|en)?/brands',
            name: 'brands',
            components: {
               
                theme8: BrandsView8,
            }
        },
        {
            path: "/:lang(ar|en)?/bestSeller",
            name: 'bestSeller',
            components: {
               
                theme8: theme8_products,
            }
        },

        {
            path: '/:lang(ar|en)?/products',
            name: 'products',
            components: {
                
                theme8: theme8_products,
            }
        },

        {
            path: "/:lang(ar|en)?/latestProducts",
            name: 'latestProducts',
            components: {
             
                theme8: theme8_products,
            }
        },

        {
            path: "/:lang(ar|en)?/FeaturedProducts",
            name: 'FeaturedProducts',
            components: {
             
                theme8: theme8_products,
            }
        },
        
        {
            path: "/:lang(ar|en)?/product/:id/:name",
            name: 'product',
            components: {
               
                theme8: productView8,
            }
        },
        {
            path: "/:lang(ar|en)?/brand/:id/:name",
            name: 'brand',
            components: {
                
                theme8:BrandView8

            }
        },
        {
            path: "/:lang(ar|en)?/shoppingCart",
            name: 'shoppingCart',
            components: {
                
                theme8: ShoppingCartView8,
            }
        },

        {
            path: "/:lang(ar|en)?/shopping_cart",
            name: 'shopping_cart',
            components: {
               
                theme8: ShoppingCartView8,
               
            }
        },
        {
            path: "/:lang(ar|en)?/confirmOrder",
            name: 'confirmOrder',
            components: {
               
                theme8: ConfirmOrderView8,
            }
        },

        {
            path: '/:lang(ar|en)?/checkout',
            name: 'checkout',
            components: {
               
                theme8: ConfirmOrderView8,
              
            }
        },
        {
            path: "/:lang(ar|en)?/Search/:word",
            name: 'search',
            components: {
                
                theme8: SearchView8,
            }
        },
        {
            path: "/:lang(ar|en)?/notifications",
            name: 'notifications',
            components: {
              
                theme8: NotificationsView8,
            }
        },
        {
            path: "/:lang(ar|en)?/confirmorder_guest",
            name: 'confirm_order_guest',
            components: {
               
                theme8: ConfirmOrderGuestView8,
            }
        },
        {
            path: '/:lang(ar|en)?/guest_order/success/:id',
            name: 'order_done',
            components: {
              
                theme8:OrderDone8,
            }
        },

        

    ]
    //const storeName = 'marqat'

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    

})


export default router